import 'lazysizes';
import 'swiper/css';
import "swiper/css/navigation"
import "swiper/css/pagination"
import './src/styles/theme.scss';
// import * as gtag from 'gatsby-plugin-google-gtag'
import ReactDOM from "react-dom/client";

// export const onRouteUpdate = ({ location }) => {
//   if (process.env.NODE_ENV !== 'production') {
//     return null
//   }

//   const pagePath = location
//     ? location.pathname + location.search + location.hash
//     : undefined
//   setTimeout(() => {
//     if (typeof gtag === 'function') {
//       gtag('event', 'page_view', { page_path: pagePath })
//     }
//   }, 100)
// }

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}